<template>
    <div class="code">
        <!--  -->
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    computed:{
    },
    components:{
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.code{
    width: 100%;
    height: 100%;
    background: url('../assets/code.png');
    background-attachment:fixed;
    background-position:center;
    position: relative;
}

</style>
